import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import BlogPage from '~/components/Blog';
import { addNonBreakingSpace } from '~/utils/add-non-breaking-space';
import { useBlogCategories } from '~/hooks/graphql/queries/use-blog-categories';

export default function Blog({ data, location }) {
  const { page, articleGallery } = data;
  const articleGalleryWithNonBreakingTitles = {
    ...articleGallery,
    articles: articleGallery.articles.map((article) => ({
      ...article,
      title: addNonBreakingSpace(article.title),
    })),
    featuredArticle: {
      ...articleGallery.featuredArticle,
      title: addNonBreakingSpace(articleGallery.featuredArticle.title),
    },
  };

  const categories = useBlogCategories();

  return (
    <Layout transparentNav={page.transparentNav} navColor={page.navColor}>
      <Metadata title={page.name} />
      <BlogPage
        page={page}
        articleGallery={articleGalleryWithNonBreakingTitles}
        location={location}
        categories={categories}
      />
    </Layout>
  );
}

export const query = graphql`
  query BlogPage {
    page: contentfulPage(slug: { eq: "blog" }) {
      ...ContentPageFragment
    }
    articleGallery: contentfulArticleGallery(slug: { eq: "blog" }) {
      ...ArticleGalleryFragment
    }
  }
`;
