import { Heading, Grid, Box } from 'theme-ui';
import FeaturedArticleCard from '../Article/Card/Blog/Featured';
import ArticleList from '../Article/List';
import { getUniqueCategoriesFromArticles, sortArticlesByCategory } from '~/utils/blog-category/index.cjs';
import CategoryBar from './Category/Bar';

export default function BlogPage({ page, articleGallery, location }) {
  const { backgroundColor } = page;
  const { articleListTitle, articles, featuredArticleTitle, featuredArticle } = articleGallery;

  const categories = getUniqueCategoriesFromArticles(articles);
  const articlesByCategory = sortArticlesByCategory(categories, articles);

  return (
    <Box
      sx={{
        '--go-back-height': '4.8rem',
        background: backgroundColor ?? 'transparent',
      }}
    >
      <CategoryBar location={location} categories={categories} />
      <Grid
        sx={{
          py: ['4.8rem', '9.6rem'],
          px: ['2.4rem', '4rem'],
          maxWidth: 'var(--max-width)',
          mx: 'auto',
        }}
      >
        <Heading
          sx={{
            mb: ['1.6rem', '2.4rem '],
            fontSize: ['2.8rem', '3.6rem'],
            textTransform: 'uppercase',
            fontWeight: 'medium',
            lineHeight: 0.9,
            letterSpacing: '-0.03em',
          }}
        >
          {featuredArticleTitle}
        </Heading>
        <FeaturedArticleCard article={featuredArticle} />
        <ArticleList articlesByCategory={articlesByCategory} articleListTitle={articleListTitle} location={location} />
      </Grid>
    </Box>
  );
}
